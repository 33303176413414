import React from 'react';

import ListDropDown from '../../../default/ListDropDown'

export default function Faq(){
  return(
      <section className="section section-faq  ">
          <div className=" container ">
              <h2 className="section-title "><span>FAQ</span></h2>
              <ListDropDown class={FaqData.class} data={FaqData.data} />
           </div>
      </section>
  )

} 

const FaqData = {
  class:'collapsible',
  data:[
    { id:1,
      title:'행사장 내 주차가 가능한가요 ?',
      text:'별도로 주차권을 제공해드리지 않고 있습니다. 가급적이면 대중교통을 사용 부탁드립니다',
    },
    { id:2,
      title:'취소 및 환불은 어떻게 할 수 있나요?',
      text:<span className="full">
        행사 신청 취소 및 환불 절차는 모비아카데미 규정을 따릅니다. 행사 시작 전까지는 취소/환불이 가능하나, <br className="pc"/>
        결제 수단에 따라 수수료가 부과될 수 있습니다. <br className="mobile"/>행사 시작 후에는 결제 취소/환불이 불가합니다.</span>,
    },
    { id:3,
      title:'참가 증명서 신청 가능한가요?',
      text:<span className="full">참가 증명서가 필요하신 분들은 <a href="https://docs.google.com/forms/d/e/1FAIpQLSflzp-b67umBnlPFxkXdCafJc1igpBXCicbeUzQvC-4LhJDRw/viewform" 
         target="_blank">여기</a>에 <br className="pc"/>
      정보를 기입하여 주시면 행사 종료 후 영업일 기준 3일 이내에 참가 증명서를 발송드립니다.</span>,
    },
  ]
}




