import React from 'react';

export default function UlBox(props){
    return(
        <ul className={props.class}>
            {props.data.map((con,i)=>{
                console.log(con.subClass)
                return(<li key={i} className={con.on}>
                            <img src={con.src} alt={con.alt}/> 
                            <strong>{con.tit}</strong> 
                            <span className="small">{con.name}</span>
                            { con.subClass != null ? 
                                <List subClass={con.subClass} sub={con.sub}/> 
                                : '' }
                        </li>)
            })}
        </ul>
    )
}

const List = (props) =>{
    return(<>
    <ul className={props.subClass}>
    {props.sub.map((item,i) =>{
        return( <li key={i}>{item.tit}</li> )
    })}
    </ul>
    </>)
}
