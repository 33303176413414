import React, { Component } from 'react';

export default function Sponsored(prop){
    const data = prop.data;
    return(
        <section className="section section-family sponsored ">
            <h2 className="container"><span className="section-title sponsored-title color-dark ">Sponsored</span></h2>
            <div className="section-con sponsored-con container">
                { data.map( (con, i) => {
                    return( <SponserBox src={con.src}
                                        alt={con.alt}
                                        link={con.link}
                                        key={i} /> );
                })}
            </div>
        </section>
    )
}

const SponserBox = (props) => {
    return(
        <div className="sponsored-con-box">
            <div className="family-logo"><a href={props.link} target="_blank"><img src={props.src} alt={props.alt} /></a></div>
        </div>
    )
}
