
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

// import { Icon } from 'react-materialize';

import MetaHead from './helmet';

import Footer from '../../../default/Footer';
import Header from '../Header/';
import TabNavi from '../../../default/TabNavi';

import SubVisual from '../Visual/SubVisual';
import SubSession from '../Session/'; //폴더


export default function Session ({ match }){
    return (
        <>
            {/* meta tag */}
            <MetaHead />

            <Header path={match.path}/>
            {/* <TabNavi class={TabNaviData.class} data={TabNaviData.data} /> */}
            <SubVisual title={subVisualData.title} />
            <SubSession />
            <Footer />

        </>
    );
}

const subVisualData = {
    title:'session'
}

