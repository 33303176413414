import React, { useEffect, useState, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Link, Switch, Route, } from 'react-router-dom';
// import { Icon } from 'react-materialize';
// import M from "materialize-css";

import TopButton from '../../../default/TopBtn';
import Gnb from '../../../default/Gnb';


// let wid = window.innerWidth;
// let origin = process.env.PUBLIC_URL;
// let gnb = document.querySelector('#mobile-gnb');
// const path = origin;

const path = '/'
export default function Header() {
    const [visible, setVisible] = useState(false);
    const headFix = () => {
        const head = document.querySelector('header');
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        scrollTop > 0 ? setVisible(true) : setVisible(false);
        console.log(scrollTop)
    }

    useEffect(() => {
        window.addEventListener('scroll', headFix);
    }, []);

    const scrollTop = event => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    //tab 스크롤
    const divPosition = (item) => {
        let absoluteTop = window.pageYOffset + document.querySelector(item).getBoundingClientRect().top;
        window.innerWidth < 800 ? window.scrollTo(0, Math.floor(absoluteTop) - 100)
            : window.scrollTo(0, Math.floor(absoluteTop));
    }
    const scrollOffset = (item) => {
        if (window.location.pathname === '/award' ||
            window.location.pathname === '/session') {
            window.location.href = window.location.origin + '/' + item;
            divPosition(item)
        }
        divPosition(item);
    }


    //DATA
    const defaultHeadInfo = {
        blindTitle: 'max the creative | 맥스더크리에이티브 | 맥스 더 크리에이티브',
        Logo: {
            to: path,
            imgClass: 'head-logo-img',
            text: <>eltower 7F Grand Hall<br />2023.6.28 ~ 6.29</>
        }
    }

    const gnbData = {
        class: `flexBox-nowrap head-con-navi mobile-gnb`,
        id: 'mobile-gnb',
        data: [
            {
                class: 'tab',
                href: `${path}session`,
                name: 'session',
                click: scrollTop
                //   click: (e)=>{e.preventDefault();alert('준비중 입니다.')} 
            },
            // {
            //     class: 'tab',
            //     href: `${path}award`,
            //     name: 'award',
            //     click: scrollTop
            // },
            // { class:'tab',
            //   href:`${path}faq`,
            //   name:'faq',
            //   click: scrollTop },
            // { class:'tab pc target',
            //   href:`https://www.facebook.com/maxsummit2019/`,
            //   name:<><i className="custom-icon">&#xe800;</i></>,
            //   linkClass:'btn-link i-facebook'
            // },
            {
                class: 'tab test',
                href: `${path}#apply`,
                name: 'apply',
                linkClass: 'btn-style waves-effect waves-red',
                click: (e) => { scrollOffset(e.target.hash) },
            },
            // {
            //     class: 'tab test',
            //     href: `${path}#ticket`,
            //     name: 'ticket',
            //     linkClass: 'btn-style waves-effect waves-red',
            //     click: (e) => { scrollOffset(e.target.hash) },
            //     //   click:(e)=>{
            //     //       e.preventDefault();
            //     //       alert('티켓 구매는 8/7(금)에 업데이트 예정입니다.');
            //     //   }
            // },
            // { class:'tab target',
            //   href:`/2019/1st/index.html`,
            //   name:'2019 1st',
            //   linkClass:'btn-style waves-effect waves-red',
            //   click: scrollTop 
            // },          
        ]
    }


    return (
        <>
            <header className={` ${visible ? 'fix' : ''}`} >
                {/* 종료일 추가 하는 배너 */}
                {/* <div className="head-banner">
                <div className="container">
                    <span>MAX THE CREATIVE 2nd 성황리에 종료되었습니다.
                            <br className="mobile"/> 행사 후기를 남겨주시면 추천을 통해 경품을 드려요!</span>
                    <a href="https://forms.gle/4fu2ZEb3r2TQ6UzX6" target="_blank"
                        className="btn-style" rel="noopener noreferrer" >리뷰작성하기</a>
                </div>
             </div> */}
                {/* 기본 */}
                <div className="head head-pc row container flexBox-nowrap">
                    <h1 className="blind">{defaultHeadInfo.blindTitle}</h1>
                    <Link to={defaultHeadInfo.Logo.to}
                        className="head-logo"
                        onClick={scrollTop} rel="noopener noreferrer">
                        <span className={defaultHeadInfo.Logo.imgClass}></span>
                        <span className="head-logo-txt">{defaultHeadInfo.Logo.text}</span>
                    </Link>
                    <div className="head-contents " >
                        <Gnb class={gnbData.class}
                            id={gnbData.id}
                            data={gnbData.data} />
                    </div>
                </div>
            </header>

            <TopButton />
        </>
    )

}



