import React, { Component } from 'react';

export default function Sponsored(prop) {
    const data = prop.data;
    return (
        <section className="section section-family sponsored ">
            <h2 className="container"><span className="section-title sponsored-title color-dark ">Sponsored</span></h2>
            {/* <div className="section-con sponsored-con container">
                { data.map( (con, i) => {
                    return( <SponserBox src={con.src}
                                        alt={con.alt}
                                        link={con.link}
                                        key={i} /> );
                })}
            </div> */}
            <div className="section-con-txt container box-con-txt" style={{ width: '50%', maxWidth: '700px', padding: '50px 2% 30px', minHeight: 'auto' }}>
                <div style={{ display: 'inline-block', marginTop: '0 ' }}>
                    <p className='b' style={{ marginBottom: '2%' }}>MAX THE CREATIVE 4th 후원사 마감</p>
                </div>
                <div className=" section-con-btn" style={{ display: 'inline-block', padding: '2% 0', textAlign: 'center', marginTop: '0' }}>
                    {/* <a href="./pdf/2023/[MAX the Creative 4th] 후원안내서.pdf"
                        download type="application/pdf"
                        rel="noopener noreferrer"
                        className="section-con-btn ">
                        <span className="btn-style2 waves-effect waves-red font-color-red ">
                            후원사 가이드 보기
                        </span>
                    </a> */}
                    <span className="btn-style2 font-color-red " style={{ fontWeight: "normal" }}>
                        후원사 모집이 마감되었습니다. 감사합니다.
                    </span>
                </div>
            </div>
        </section >
    )
}

const SponserBox = (props) => {
    return (
        <div className="sponsored-con-box">
            <div className="family-logo"><a href={props.link} target="_blank"><img src={props.src} alt={props.alt} /></a></div>
        </div>
    )
}
