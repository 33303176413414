import React from 'react';

import TextBox from '../../../default/TextBox';
import CardButton from '../../../default/CardButton';

export default function Apply(){
    return(
        <section className="section section-main  ">
            <div className="container ">
                <TextBox title={applyText.title} 
                         text={applyText.text}
                         class={applyText.class} />
                <article className="section-target scrollspy" id="apply">
                    {/* <CardButton class={cardButtonData.class} 
                                id={cardButtonData.id} 
                                data={cardButtonData.data} /> */}
                    <div className="section-con row">
                        <div className={`section-con-box col s6`}>
                            <h2 className="section-title ">연사사</h2>
                            <div className="section-con-txt box-con-txt">
                                <p className="b">다양한 형태로 광고주의 크리에이티브적 고민을 해결해주는 회사</p>
                                <p>선별된 20개 참여 기업이 크리에이티브 관련 세션 진행</p>
                                <div>
                                    <a href="./pdf/MTC 3rd_연사사 대상 안내.pdf" 
                                        download type="application/pdf"
                                        rel="noopener noreferrer" 
                                        className="section-con-btn ">
                                        <span className="btn-style2 waves-effect waves-red font-color-red ">
                                            연사사 가이드
                                        </span>
                                    </a>
                                    <a href="https://forms.gle/LwP14Bejoj7a9qTX7" 
                                        // onClick={(e)=>{e.preventDefault();alert('접수가 마감되었습니다. 다음에 행사 때 많은 지원 부탁드립니다.')}}
                                        target="_blank" className="section-con-btn ">
                                        <span className="btn-style2 waves-effect waves-red font-color-red ">
                                            연사사로 신청하기
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={`section-con-box col s6`}>
                            <h2 className="section-title ">참석 광고주</h2>
                            <div className="section-con-txt box-con-txt">
                                <p className="b">새로운 크리에이티브에 관심이 많고, 디지털 기반의 환경 속에서 자사 서비스를 효과적으로 마케팅하고자 하는 광고주</p>
                                <p>마케팅에 대해 깊은 이해와 고민을 하고 계신 300개 기업의 마케터로 한정 어워드 심사위원으로 참여 및 우수 참여 기업 선정 예정</p>
                                <div>
                                    <a href="./pdf/MTC 3rd_광고주 대상 안내.pdf" 
                                        download type="application/pdf"
                                        rel="noopener noreferrer" 
                                        className="section-con-btn ">
                                        <span className="btn-style2 waves-effect waves-red font-color-red ">
                                            참석 광고주 가이드
                                        </span>
                                    </a>
                                    <a href="https://forms.gle/8kqVgqADbtvHsGBK9" 
                                       target="_blank" className="section-con-btn ">
                                        <span className="btn-style2 waves-effect waves-red font-color-red ">
                                            참석 광고주로 신청하기
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    )
}



// DATA
const applyText = {
    class:'text-center',
    title:'ABOUT',
    text:<>8 년간 광고 업계의 주요 행사로 자리잡은 MAX Summit 이 크리에이티브 전략을 중심으로 하는 MAX The Creative 를 개최합니다.<br/> 
    광고업계에서 자기만의 개성과 새로운 크리에이티브 전략을 중심으로 떠오르고 있는 회사들이 인사이트를 공유하며 <br className="pc"/> 
    광고주분들과 교류의 장을 함께 합니다</>
}

