
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

import MetaHead from './helmet';
import Footer from '../../../default/Footer';
import Header from '../Header/';

import SubAward from '../Award/';
import SubVisual from '../Visual/SubVisual';



export default function Award ({ match }){
    return (
        <>
            <MetaHead />
            <Header path={match.path}/>
            <SubVisual title={subVisualData.title} />
            <SubAward />
            <Footer />
        </>
    );
}

const subVisualData = {
    title:'award'
}




