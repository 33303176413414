import React,{useEffect,useState,useLayoutEffect} from 'react';
import { Link,Router,Switch,Route, } from 'react-router-dom';

import M from "materialize-css";

export default function Gnb(props){
    //반응형 window width
    const [size, setSize] = useState([0]);
    function useWindowSize() {
        useLayoutEffect(() => {
          function updateSize() {
            setSize([window.innerWidth]);
          }
          window.addEventListener('resize', updateSize);
          updateSize();
          return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    useEffect(()=>{
        //모바일 메뉴 - 드롭다운 up/down
        let menuBtn = document.querySelectorAll('.dropdown-trigger');//햄버거아이콘
        M.Dropdown.init( menuBtn );
        //tab 메뉴 - 드롭다운 side
        let dropBtn = document.querySelectorAll('.drop-menu-btn');
        M.Dropdown.init( dropBtn );

        // //메뉴 클릭시 위치로 
        // var elems = document.querySelectorAll('.scrollspy');
        // var instances = M.ScrollSpy.init(elems,{
        //     scrollOffset:100
        // });
    },[])  

    const scrollTop = event =>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    
    const mobileClass = useWindowSize() < 800 ? 
                            props.class+' dropdown-content' : 
                            props.class+'';
    
    return(<>
        <ul className={ mobileClass} 
            id={props.id}>
            {props.data.map((con,i)=>{
                return(
                    <li className={`${con.class} ${props.path == con.href ? 'on' : ''}`} key={i} >
                        {con.class.indexOf('target') > -1 ?
                            <a href={con.href}  
                               onClick={con.click }
                               className={con.linkClass} 
                               target="_blank" >{con.name}</a> 
                        : <Link to={con.href} 
                                onClick={con.click }
                                className={con.linkClass}
                                rel="noopener noreferrer" >{con.name}</Link >
                        }
                    </li>
                )
            })}
        </ul>
        <Link to={'javascript:;'} 
              className="mobile-gnb-btn dropdown-trigger mobile" 
              data-target="mobile-gnb" 
              rel="noopener noreferrer" >
            <i className="large material-icons">menu</i>
        </Link >
        {/* <div className="head-btn ">
            <a href={`/2019/1st/index.html`} 
               className="btn-style waves-effect waves-red" 
               data-target="drop-menu" target="_blank">2019 1st</a>
        </div> */}
    </>)
}
