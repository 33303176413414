import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';

import Root from './client/Root';
import './index.css';

import * as serviceWorker from './serviceWorker';


window.onload=()=>{
    document.querySelector('.loading').style.display='none';
}

ReactDOM.render(<>
    {/* <Loading /> */}
    <Root />
</>,  document.getElementById('root') );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
