import React, { useState, useEffect } from 'react';
import { Icon, Modal } from 'react-materialize';
import M from "materialize-css";
// import e from 'express';

export default function Visual() {
    const handleClick = event => {
        let video = document.getElementById('myVideo');
        video.src = "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fmaxsummit2019%2Fvideos%2F2731568677076983%2F"
        // video.src="https://www.youtube.com/embed/x4TWV8ztomc?enablejsapi=1;version=3;playerapiid=ytplayer"
        M.Modal.init(Modal);
    }

    const videoStop = event => {
        let video = document.getElementById('myVideo');
        video.src = null; //opera
        video.src = ""; //chrome
        video.mozSrcObject = null; //firefox
    }

    return (
        <div className="visual">
            <div className="visual-con container">

                <div className="visual-con-box visual-con-box1">
                    <h2 className="visual-con-box">
                        <span className={`visual-con-box-txt move font-color-red`}>max</span>
                        <span className={`visual-con-box-txt move `}>the</span>
                        <span className={`visual-con-box-txt move `}>creative</span>
                        <span className={`visual-con-box-txt move last`}>4th</span>
                    </h2>
                    <div className={`visual-con-box-txt move ko`}>
                        Discover the next creative <img src={`${process.env.PUBLIC_URL}/2019/images/icon_01.png`} alt="번개모양" />
                    </div>
                </div>

                <div className="visual-con-box visual-con-box2">
                    {/*<div className="visual-video">
                          <a href="#modalVideo" className="visual-video-btn modal-trigger" 
                            rel="noopener noreferrer" 
                            onClick={ handleClick }
                        >
                            <img src={`${process.env.PUBLIC_URL}/2019/images/icon_02.png`} alt="동영상플레이버튼" />
                        </a> 
                    </div>   */}

                    {/* <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmaxsummitbymobidays%2Fvideos%2F1599232033873998%2F&show_text=false&width=560&t=0"
                        width="560" height="315"
                        // scrolling="no" frameborder="0" 
                        // allowTransparency="true" allowFullScreen="true"
                        id="myVideo" ></iframe> */}

                    {/* <ModalVideo click={videoStop}/> */}

                    <video controls id="myVideo" >
                        <source src='2023_4th/video/mtc.mp4'></source>
                    </video>
                </div>

                <div className="fin-btn">
                    <a href="https://fb.watch/jIJ_6ewwQm/"
                        className="btn-style2"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={handleClick}>세션영상 보러가기</a>
                </div>
            </div>
        </div>
    )
}



//비디오 모달 띄우기
const ModalVideo = (prop) => {
    return (
        <Modal id="modalVideo" className="modal modal-video">
            <div className="modal-content">
                <a href="" className="modal-close modal-close-right waves-effect waves-green btn-flat"
                    rel="noopener noreferrer"
                    onClick={prop.click}>
                    <Icon className="font-color-w">close</Icon>
                </a>

                <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fmaxsummit2019%2Fvideos%2F2731568677076983%2F"
                    width="560" height="315"
                    // scrolling="no" frameborder="0" 
                    // allowTransparency="true" allowFullScreen="true"
                    id="myVideo" ></iframe>

                {/* <iframe src="https://www.youtube.com/embed/x4TWV8ztomc?enablejsapi=1;version=3;playerapiid=ytplayer" 
                        width="1094" height="615" allowFullScreen
                        allow="accelerometer; autoplay; encrypted-media;" 
                        id="myVideo" ></iframe> */}

                {/* <video id="myVideo" width="1094" height="615" controls >
                    <source src={`${process.env.PUBLIC_URL}/images/mtc_2.mp4`} type="video/mp4" />
                </video> */}
            </div>
        </Modal>
    )
}

