import React from 'react'

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <div className="container center-align foot">
                <div className="foot-btn left">
                    <a className="btn-link i-facebook" target="_blank"
                        href="https://www.facebook.com/maxsummitbymobidays/">
                        <i className="custom-icon">&#xe800;</i>
                    </a>
                    <a className="btn-link i-facebook" target="_blank"
                        href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hello@maxsummit.co&su=[ 문의 ]">
                        <i className="material-icons">mail_outline</i>
                    </a>
                </div>
                <a className="foot-copy center" target="_blank"
                    href="http://www.mobidays.com/">
                    &#169;{currentYear} Mobidays, Inc. All Rights Reserved.
                </a>
                <div className="foot-btn right">
                    <a className="btn-style foot-btn1" target="_blank"
                        href="http://www.mobidays.com/">mobidays</a>
                    <a className="btn-style foot-btn1" target="_blank"
                        href="/2020/3rd">MTC 3rd</a>
                </div>
            </div>
        </footer>
    )
}
