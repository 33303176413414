
import React from 'react';
import MetaHead from './helmet';

import Footer from '../Footer';
import HeaderComing from '../Header/Coming';

import SubVisual from '../Visual/SubVisual';
import SessionModule from '../Session/SessionLayout';


export default function Session ({ match }){
    return (
        <>
            <MetaHead />
            <HeaderComing path={match.path}/>
            <SubVisual title={subVisualData.title} />
            <SessionModule/>
            <Footer />
        </>
    );
}

const subVisualData = {
    title:'session'
}





