import React from 'react';

export default function SessionCon(props) {
    let t = ['09:50- 10:00', '11:40- 12:50', '15:00- 15:30 ', '15:30- 16:00', '17:00-', '17:30-', '17:00-17:10', '17:10-'];
    return (<li className={
        props.time === t[7] || props.time === t[6] || props.time === t[5] || props.time === t[4] || props.time === t[3] || props.time === t[2] ||
            props.time === t[1] ||
            props.time === t[0] ? 'breakTime' : ''} style={{ alignItems: "center" }}>
        <span className="sess-time ">{props.time}</span>
        <span className="sess-txt ">{props.title}</span>
        <span className="sess-user ">
            <span className="sess-user-job ">{props.userJob}</span>
            <span className="sess-user-name ">{props.userName}</span>
        </span>
    </li>)
}