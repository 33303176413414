import React from 'react'

function Footer (){
    return(
        <footer>
            <div className="container center-align">
                <span>&#169;2019 Mobidays, Inc. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export default Footer;