import React, { useEffect, useState } from 'react';

import SessionCon from './textList';
// import Data from './sessionData.js';
import Data from './data.json';
import TabNavi from '../../../default/TabNavi';


export default function Session(props) {
  const [visible, setVisible] = useState(false);
  const [top, setTop] = useState(0)
  const navFix = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    scrollTop > 100 ? setVisible(true) : setVisible(false);
  }
  useEffect(() => {
    window.addEventListener('scroll', navFix);
  }, [])

  //스크롤시 fix 클래스명
  const navFixClass = visible ? TabNaviData.class + ' fix'
    : TabNaviData.class + '';
  // fix시 top 위치조정
  const navPosition = () => {
    const head = document.querySelector('header');
    const nav = document.querySelector('.main-sub-head');

    if (visible) {
      setTop(head.offsetHeight)
      nav.style.top = top + 'px';
    } else {
      setTop(0)
      nav.style.top = top;
    }
  }
  useEffect(() => {
    navPosition();
  })


  return (
    <div className="contents">
      <section className="section section-timeline" >
        <TabNavi class={navFixClass}
          data={TabNaviData.data} />
        {/* <div className="section-con container " style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "50vh", textAlign: "center" }}> */}
        <div className="section-con container " >
          {/* <h2 className="section-title section-title-btn">
            <a className="btn-style2" target="_blank"
              href="https://forms.gle/oXTHapW5jVL3We7f8">대행사 만나러 가기</a></h2> */}

          <div className="section-con-box scrollspy" id="day1">
            <h3 className="section-con-title ">day 1</h3>
            <ul className="section-con-txt ">
              {Data.day1.map((con, index) => {
                return (<SessionCon time={con.time}
                  title={con.title}
                  userJob={con.userJob}
                  userName={con.userName}
                  key={index} />);
              })}
            </ul>
          </div>

          <div className="section-con-box scrollspy" id="day2">
            <h3 className="section-con-title ">day 2</h3>
            <ul className="section-con-txt ">
              {Data.day2.map((con, index) => {
                return (<SessionCon time={con.time}
                  title={con.title}
                  userJob={con.userJob}
                  userName={con.userName}
                  key={index} />);
              })}
            </ul>
          </div>

          {/* <div >
            <h2 className="section-title " style={{ fontSize: "3rem" }}>COMMING SOON</h2>
            <p className="section-con-txt " style={{ paddingTop: "3%" }}>세션 타임테이블이 곧 업데이트 될 예정입니다.</p>
          </div> */}

        </div>
      </section>
    </div>
  )
}

const TabNaviData = {
  class: 'main-sub-head session-nav',
  data: [
    {
      class: 'tab',
      href: '#day1',
      linkClass: 'btn-link',
      dataRel: '#day1',
      name: 'day1'
    },
    {
      class: 'tab',
      href: '#day2',
      linkClass: 'btn-link',
      dataRel: '#day2',
      name: 'day2'
    },
  ]
}



