import React from "react";
import { Helmet } from "react-helmet";

// import Data from '../data/helmetData'

export default function metaHead(props) {
    // ogTitleCon ogDescriptionCon

    return (
        <>
            <Helmet>
                <title>맥스더크리에이티브 | MAX THE CREATIVE 3rd | 2022 Coming Soon</title>
                {`<!-- 오픈 정보 -->`}
                <meta name="title" content="맥스더크리에이티브 | MAX THE CREATIVE 3rd | 2022 Coming Soon" />
                <meta name="description" content="대한민국 최대 디지털 마케팅 크리에이티브 축제에 마케터 여러분을 초대합니다." />
                <meta name="image" content="https://maxthecreative.co/mtc_thumbnail_2022.png?ver=1" />

                {`<!-- 오픈그래프 -->`}
                <meta property="og:title" content={props.ogTitleCon} />
                <meta property="og:description" content={props.ogDescriptionCon} />
                <meta property="og:image" content={props.ogImageCon} />

                {`<!-- Facebook Pixel Code -->`}
                <script type="text/javascript">{`
                {
                    ! function (f, b, e, v, n, t, s) {
                        if (f.fbq) return;
                        n = f.fbq = function () {
                            n.callMethod ?
                                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                        };
                        if (!f._fbq) f._fbq = n;
                        n.push = n;
                        n.loaded = !0;
                        n.version = '2.0';
                        n.queue = [];
                        t = b.createElement(e);
                        t.async = !0;
                        t.src = v;
                        s = b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t, s)
                    }(window, document, 'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '468289743724072');
                    fbq('track', 'PageView');    
                }
            `}</script>
                <noscript>{`
                <img height="1" width="1" alt="facebook" style="display:none"
                     src="https://www.facebook.com/tr?id=468289743724072&ev=PageView&noscript=1https://www.facebook.com/tr?id=468289743724072&ev=PageView&noscript=1" />
            `}</noscript>
                {`<!-- End Facebook Pixel Code -->`}

                {`<!-- [ 구조화 데이터 및 채널 제출 ] -->`}
                {`<!-- 맥스더크리에이티브 -->`}
                <script type="application/ld+json">{`
            {
                "@context": "http://schema.org",
                "@type": "Event",
                "name" : "MAX The Creative 3rd",
                "location": {
                "@type": "Place",
                "name": "ELtower",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "213 Gangnam-daero",
                    "addressLocality": "Seocho-gu",
                    "postalCode": "06749",
                    "addressRegion": "Seoul",
                    "addressCountry": "Republic of Korea"
                }
                },
                "startDate": "2019-11-12T09:00",
                "endDate": "2019-11-13",
                "url": "https://maxthecreative.co/",
                "logo": "https://maxthecreative.co/mtc_thumbnail_2022.png?ver=1",
                "contactPoint": [{
                "@type": "ContactPoint",
                "email": "hello@maxthecreative.co",
                "contactType": "customer support"
                }],
                "sameAs": [
                "https://www.facebook.com/maxsummit2019",
                "https://www.youtube.com/channel/UCImcWLV6kq702b6lZZQm25A"
                ]
            }
            `}</script>

                <link rel="stylesheet" href="/2020_3rd/css/style_3rd.css" />
                <link rel="stylesheet" href="/2020_3rd/css/session.css" />
                <link rel="stylesheet" href="/2020_3rd/css/award.css" />


                {/* <body class="dark" /> */}
            </Helmet>
        </>
    );
};


