
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import { Modal, Button } from 'react-materialize';
import M from "materialize-css";
// import ReactLoading from "react-loading";

import MetaHead from './helmet';

import Footer from '../../../default/Footer';
import Header from '../Header/'; //폴더
import TabNavi from '../../../default/TabNavi';

import Visual from '../Visual';
import Services from '../components/Services';
import Sponsored from '../components/Sponsored';
// import Agency from '../components/Agency';
import Apply from '../Apply';
// import Gallery from '../../../default/Gallery';
// import Award from '../Award';
import Ticket from '../Apply/Ticket';
// import Location from '../../../default/Location';
import Faq from '../Faq/FaqLayout';
import Contactus from '../components/Contactus';
// import $ from "jquery";



export default function Main({ match }) {
  useEffect(() => {
    const modal = document.querySelector('#modalInfo');
    M.Modal.init(modal);

    // function hidepop(){
    //   var popbg = $('.modal-marketer').siblings('.modal-overlay'),
    //       popup = $('.modal-marketer');
    //   var today = new Date();
    //   var selectDay = new Date('2021-3-22 23:59');
    //   if( today.getTime() >= selectDay.getTime() ){
    //     popbg.css({
    //       opacity:0,
    //       display:'none'
    //     });
    //     popup.css({
    //       opacity:0,
    //       display:'none',
    //     })
    //     $("body").css({
    //       overflow: 'visible'
    //     })
    //   }
    // }
    // hidepop();

  }, [])

  return (
    <>
      {/* <Loading />  */}
      {/* meta tag */}
      <MetaHead />

      <Header path={match.path} />
      {/* <MainNav /> */}
      {/* <TabNavi class={TabNaviData.class} data={TabNaviData.data} /> */}
      <Visual />
      <div className="contents">
        <Sponsored data={SponsoredInfo} />
        <Apply />
        {/* <Gallery src={gallerySrc} /> */}
        {/* <Agency /> */}
        {/* <Award /> */}
        <Ticket />
        {/* <Location imgSrc={locationData.imgSrc}
                          imgAlt={locationData.imgAlt}
                          data={locationData.data} /> */}
        <Faq />
        <Services />
        <Contactus data={MetaHead.defaultProps} />
      </div>
      <Footer />

      {/* <Modal actions={[
                      <Button flat modal="close" node="button" 
                              waves="green"
                              className="">Close</Button>
                   ]}
                   open={true}
                  //  root={[object HTMLBodyElement]}
                   className="modal-info" >
              <div className="modal-imgBox" >
                <img src="/2020_3rd/images/mtc-popup.jpg" alt="" />
              </div>
            </Modal> */}
      <Modal actions={[
        <Button flat modal="close" node="button"
          waves="green"
          className="">Close</Button>
      ]}
        open={true}
        //  root={[object HTMLBodyElement]}
        className="modal-info modal-marketer" >
        <div className="modal-imgBox" >
          <a href="https://www.mobiinside.co.kr/2021/03/15/mobidays/" target="_blank">
            <img src="/2020_3rd/images/marketer.jpg" alt="" />
          </a>
        </div>
      </Modal>
    </>
  );
}


// DATA
const SponsoredInfo = [
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-tiktok.png',
    alt: '틱톡',
    link: 'https://www.tiktok.com/'
  },
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-teads.png',
    alt: '한국티즈',
    link: 'https://www.teads.tv/viewable-outstream-formats/'
  },
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-adjust2.png',
    alt: '애드저스트',
    link: 'https://www.adjust.com/ko/'
  },
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-vcat.png',
    alt: 'Vcat',
    link: 'https://vcat.ai/'
  },
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-classting.png',
    alt: '클래스팅',
    link: 'https://www.classting.com/'
  },
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-tagby.png',
    alt: '태그바이',
    link: 'https://www.tagby.kr/'
  },
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-academy2.png',
    alt: '모비아카데미',
    link: 'https://www.mobiacademy.co.kr/'
  },
  {
    src: process.env.PUBLIC_URL + '/images/ci/logo-intouch.png',
    alt: '모비인터치',
    link: 'https://www.mobintouch.co.kr/'
  }
]


const locationData = {
  class: '',
  imgSrc: process.env.PUBLIC_URL + '/images/map.jpg',
  imgAlt: 'map',
  data: [
    {
      title: '주소',
      text: <>서울특별시 서초구 양재동 24번지 (강남대로 213번지)<br className="pc" />
        7층 그랜드홀</>
    },
    {
      title: '교통편',
      text: '3호선 양재역/신분당선 양재역-9번 출구'
    },
  ]
}

// const gallerySrc = '/2019/images/pic';

MetaHead.defaultProps = {
  ogTitleCon: "맥스더크리에이티브 | MAX THE CREATIVE 3rd | 2022 Coming Soon",
  ogDescriptionCon: "대한민국 최대 디지털 마케팅 크리에이티브 축제에 마케터 여러분을 초대합니다.",
  ogImageCon: "https://maxthecreative.co/mtc_thumbnail_2022.png?ver=1"
}

// const TabNaviData = {
//     class:'main-sub-head',
//     data:[
//         { class:'tab',
//           href:'#agency',
//           linkClass:'btn-link',
//           dataRel:'#agency',
//           name:'대행사 매칭'
//         },
//         { class:'tab',
//           href:'#award',
//           linkClass:'btn-link',
//           dataRel:'#award',
//           name:'award'
//         },
//         { class:'tab',
//           href:'#ticket',
//           linkClass:'btn-link',
//           dataRel:'#ticket',
//           name:'ticket'
//         },
//         { class:'tab',
//           href:'#location',
//           linkClass:'btn-link',
//           dataRel:'#location',
//           name:'location'
//         },
//         { class:'tab blind',
//           href:'#apply',
//           linkClass:'btn-link',
//           dataRel:'#apply',
//           name:'apply'
//         },
//     ]
// }


