import React from 'react';
// import {IndexRoute} from 'react-router'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

import NotFound from './NotFound';

import v2019_2nd_Main from '../2019/2nd/pageOpen/Main';
import v2019_2nd_Session from '../2019/2nd/pageOpen/Session';

import v2019_2ndfin_Main from '../2019/2nd/pageComing/Main';
import v2019_2ndfin_Session from '../2019/2nd/pageComing/Session';
import v2019_2ndfin_Award from '../2019/2nd/pageComing/Award';

import v2020_3rd_Main from '../2020/3rd/pageOpen/Main';
import v2020_3rd_Session from '../2020/3rd/pageOpen/Session';
import v2020_3rd_Award from '../2020/3rd/pageOpen/Award';

import v2023_4th_Main from '../2023/4th/pageOpen/Main';
import v2023_4th_Session from '../2023/4th/pageOpen/Session';
import v2023_4th_Award from '../2023/4th/pageOpen/Award';


export default (
    <Switch>

        {/* 2019-2nd - open[main] */}
        <Route path="/2019/2nd" exact component={v2019_2nd_Main} />
        <Route path="/2019/2nd/session" component={v2019_2nd_Session} />

        {/* 2019-2nd - finar */}
        <Route path="/2019/2nd/coming" exact component={v2019_2ndfin_Main} />
        <Route path="/2019/2nd/coming/session" component={v2019_2ndfin_Session} />
        <Route path="/2019/2nd/coming/award" component={v2019_2ndfin_Award} />

        {/* 2020-3rd - open */}
        <Route path="/2020/3rd" exact component={v2020_3rd_Main} />
        <Route path="/2020/3rd/session" component={v2020_3rd_Session} />
        <Route path="/2020/3rd/award" component={v2020_3rd_Award} />
        <Route path="/2020/3rd/vip" render={() => {
            window.location.href = "https://forms.gle/ZdeYaBvPoYUbWUjw7"
        }} />

        {/* 2023-4th - open */}
        <Route path="/" exact component={v2023_4th_Main} />
        <Route path="/session" component={v2023_4th_Session} />
        <Route path="/award" component={v2023_4th_Award} />

        {/* 3rd VIP 링크 */}
        <Route path="/vip" render={() => {
            window.location.href = "https://forms.gle/ZdeYaBvPoYUbWUjw7"
        }} />

        {/* 4th VIP 링크 */}
        <Route path="/2023/vip" render={() => {
            window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLScTLndHVpWGoeswlHEZqbXmipd30mFENsG3BZ981Muqa8Wldw/viewform"
        }} />

        <Route component={NotFound} />
    </Switch>
)

