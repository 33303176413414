import React from 'react';

export default function ListButton(props) {
    return (
        <div className={`section-con-txt border-conBox ${props.class}`}>
            {props.data.map((con, i) => {
                return (
                    <div className="ticket-box"
                        target="_blank" rel="noopener noreferrer"
                        key={i}>
                        <p className="ticket-day">{con.day}</p>
                        <p className="ticket-pay">{con.pay}</p>
                        <a href={con.href} target="_blank" className="btn-style2 waves-effect waves-red font-color-red " style={{ margin: "0 0 0 5%", fontSize: "1.1rem", borderWidth: "1px", fontWeight: "normal", padding: "15px 30px" }}>등록하기</a>
                    </div>
                );
            })}
        </div>
    )
}
