import React ,{ useEffect,useState }from 'react';

import TextUlBox from '../../../default/TextUlBox';
import TabNavi from '../../../default/TabNavi';

export default function Award(){
    const [visible,setVisible ]= useState(false);
    const [top,setTop] = useState(0)
    const navFix = ()=>{
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        scrollTop > 100 ? setVisible(true) : setVisible(false);
    }  
    useEffect(()=>{
        window.addEventListener('scroll', navFix ); 
    },[]) 

    //스크롤시 fix 클래스명
    const navFixClass = visible ? TabNaviData.class+' fix' 
                                : TabNaviData.class+'';
    // fix시 top 위치조정
    const navPosition = ()=>{
        const head = document.querySelector('header');    
        const nav = document.querySelector('.main-sub-head');
        
        if( visible ){
            setTop(head.offsetHeight)
            nav.style.top = top+'px';
        }else{
            setTop(0)
            nav.style.top = top;
        }
    }
    useEffect(()=>{
        navPosition();
    })

    return(
        <div className="contents"> 
            <section className="section section-award scrollspy " id="award">
                <TabNavi class={navFixClass} 
                         data={TabNaviData.data} />
                
                <div className="section-con container " >
                    {/* <div className="section-con-box scrollspy paddingTop" id="award-1">
                        <h3 className="section-con-title ">수상 부문</h3>
                        <div className="section-con-txt ">
                            <TextUlBox class={awardDataInfo1.class} 
                                       data={awardDataInfo1.data} />
                        </div>
                    </div> */}
                    
                    <div className="section-con-box scrollspy paddingTop" id="award-2">
                        <h3 className="section-con-title ">선정 방식</h3>
                        <div className="section-con-txt">
                            <TextUlBox class={awardDataInfo2.class} 
                                       data={awardDataInfo2.data} />
                        </div>
                    </div>
                    
                    <div className="section-con-box scrollspy paddingTop" id="award-3">
                        <h3 className="section-con-title ">수상사 특전</h3>
                        <div className="section-con-txt">
                            <TextUlBox class={awardTextImg.class} 
                                       data={awardTextImg.data} />
                        </div>
                    </div>
                </div>

                {/* <h3 className="container"><span className="section-con-title ">1st MTC 수상부문</span></h3> */}
                {/* <div className="section-con-txt ">
                    <div className="section-con-txt-box">
                        <p className="box-title-img "></p>
                        
                    </div>
                </div> */}

            </section>
        </div>
    )
}

// tab
const TabNaviData = {
    class:'main-sub-head session-nav',
    data:[
        // { class:'tab',
        //   href:'#award-1',
        //   linkClass:'btn-link',
        //   dataRel:'#award-1',
        //   name:'수상부문'
        // },
        { class:'tab',
          href:'#award-2',
          linkClass:'btn-link',
          dataRel:'#award-2',
          name:'선정 안내'
        },
        { class:'tab',
          href:'#award-3',
          linkClass:'btn-link',
          dataRel:'#award-3',
          name:'수상사 특전'
        },
    ]
}
  

// 수상 부문
const awardDataInfo1={
    class:'award-txt blind-img blind-span award-open',
    data:[
        { tit:"참석사가 행사 당일 온라인 투표 진행" },
        { tit:"총 7개 부문 수상", on:'text-sub',
          subClass:"sub",
          sub:[
              { tit:"- 모비데이즈상 [ 1팀 ]"},
              { tit:"- 플로우상 [ 2팀 ]"},
              { tit:"- 애드 저스트상 [ 2팀 ]"},
              { tit:"- 싱귤러상 [ 2팀 ]"},
          ]
        },
        { tit:"양일 중 1일 기준 모비데이즈상 외 부문별 각 1개사 선정" },
        { tit:"양일 포함 가장 많은 투표를 획득한 참여사를 모비데이즈상으로 선정" }
    ],
}

//선정 방식
const awardDataInfo2 = {
    class:'award-txt blind-img blind-span award-open',
    data:[
        { tit:"참여 광고주들의 온라인 투표로 선정됩니다." },
        { tit:<>총 7개의 시상이 있으며, 누적 득표수로 1, 2, 3위를 일자별로 선정합니다.</> },
        { tit:<>대상인 모비데이즈상은 양일간 최대 합계를 얻은 참여 기업이 수상합니다.</> },
        { tit:"", on:'text-sub',
          subClass:"sub",
          sub:[
              { tit:"[모비데이즈 상] - 1팀"},
              { tit:"[메가존 클라우드 상] - 2팀"},
              { tit:"[PubMatic 상] - 2팀"},
              { tit:"[AB180 상] - 2팀"},
          ]  
        },
    ]
}

//수상자 특전
const awardTextImg = {
    class:'award-txt blind-img blind-span award-open',//award-img
    data:[
        {tit:"상패 수여"},
        {tit:"차회 Max The Creative 자동 참여"},
        {tit:"모비인사이드 단독 수상 기념 인터뷰"},
        // { src:process.env.PUBLIC_URL+"/2019/images/icon_04.png",
        //   alt:"대상", 
        //   tit:"모비데이즈상", 
        //   name:"오버맨" 
        // },
    ]
}
