import React from 'react';

import ListButtonType2 from '../../../default/ListButton_type2';

export default function Ticket() {
    return (
        <section className="section section-ticket scrollspy " id="ticket">
            <div className="container">
                <h2 className="section-title font-transform ">Ticket</h2>
                <div className="section-con ">
                    <ListButtonType2 class={ticketData.class} data={ticketData.data} />
                    {/* <p className="ticket-alert">* 네트워킹은 양일이 아닌 13일에만 진행합니다.</p> */}
                </div>
            </div>
        </section>
    )
}

const ticketData = {
    class: '',
    data: [
        {
            href: 'https://www.mobiacademy.co.kr/application/?type=detail&idx=544&page=1&part_code=569614',
            day: <>일일권(DAY1) <br className="mobile" />+ 네트워킹 파티</>,
            pay: <>vat 포함 <strong>150,000</strong>원</>
        },
        {
            href: 'https://www.mobiacademy.co.kr/application/?type=detail&idx=545&page=1&part_code=569614',
            day: <>일일권(DAY2) <br className="mobile" />+ 네트워킹 파티</>,
            pay: <>vat 포함 <strong>150,000</strong>원</>
        },
        {
            href: 'https://www.mobiacademy.co.kr/application/?type=detail&idx=546&page=1&part_code=569614',
            day: <>양일권 <br className="mobile" />+ 네트워킹 파티</>,
            pay: <>vat 포함 <strong>250,000</strong>원</>
        }
    ]
}

